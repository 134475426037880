import React, { useState } from "react";

const IntroductionVideo = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsVideoPlaying(true);
  };

  return (
    <div className="full-glow-background-bottom">
      <div className="glow-content">
        <div className="wrapper bg-transparent my-16 py-16">
          <div className="glow-background">
            <div className="grid lg:grid-cols-2 gap-16">
              <div className="rounded-2xl overflow-hidden">
                <iframe
                  className="w-full"
                  height="500"
                  src="https://www.youtube.com/embed/mZuni-Y4o4M?si=i1n1RUcsm0tkN2Ep"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="flex items-center">
                <div>
                  <div>
                    <h1 className="font-bold text-[38px] font-min">
                      Who We Are
                    </h1>
                    <p className="text-[#718096] text-lg leading-7">
                      We're your go-to experts for cutting-edge CRM technology
                      in the forex industry.
                    </p>
                  </div>
                  <div>
                    <h1 className="font-bold text-[38px] font-min">
                      What We Do
                    </h1>
                    <p className="text-[#718096] text-lg leading-7">
                      We create tailor-made CRM solutions that streamline your
                      forex brokerage operations.
                    </p>
                  </div>
                  <div>
                    <h1 className="font-bold text-[38px] font-min">
                      Why Choose Us
                    </h1>
                    <div className="text-[#718096] text-lg leading-7">
                      <ul className="list-disc list-inside">
                        <li>
                          Industry Veterans: Years of forex tech expertise
                        </li>
                        <li>
                          Custom Solutions: Personalized CRM for your unique
                          needs
                        </li>
                        <li>
                          Innovation Leaders: Cutting-edge tools for competitive
                          edge
                        </li>
                        <li>Global Reach: Serving forex brokers worldwide</li>
                        <li>
                          Customer-Centric: Exceptional support for your success
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroductionVideo;
