import React from "react";

const BrokerConnection = () => {
  const steps = [
    {
      id: 1,
      title: "Request Your CRM Solution",
      description:
        "Begin your journey by reaching out to our team. Share your brokerage's specific needs, user requirements, and technical specifications. Our experts will review your requirements and prepare a tailored solution proposal that aligns with your business goals.",
    },
    {
      id: 2,
      title: "Experience a Personalized Demo",
      description:
        "Our team will schedule a comprehensive demonstration of our CRM system. During this session, you'll see firsthand how our features address your specific challenges. We'll walk you through the platform's capabilities, from client management to reporting tools, ensuring you understand how each feature benefits your operation.",
    },
    {
      id: 3,
      title: "Seamless Onboarding Process",
      description:
        "Once you're ready to proceed, our dedicated onboarding team takes charge. We'll create your implementation roadmap, establish timelines, and assign a project manager to guide you through each phase. This step includes initial setup, data migration planning, and user role configuration.",
    },
    {
      id: 4,
      title: "Strategic CRM Integration",
      description:
        "Our technical team carefully integrates the CRM with your existing systems. We ensure proper data flow between platforms, set up API connections, and configure trading platform integrations. This phase is handled with precision to maintain data integrity and system security.",
    },
    {
      id: 5,
      title: "Thorough Testing and Deployment",
      description:
        "Before going live, we conduct extensive testing of all system components. Our team verifies data accuracy, system performance, and user access levels. We'll run parallel tests to ensure everything works perfectly, making adjustments based on your feedback.",
    },
    {
      id: 6,
      title: "Launch Your Enhanced Operations",
      description:
        "With testing complete, you're ready to go live. Our support team remains by your side during the transition, providing training sessions for your staff and ensuring a smooth switch to the new system. We monitor performance closely and offer immediate assistance if needed.",
    },
  ];

  return (
    <div className="full-glow-background-top">
      <div className="glow-content">
        <div className="wrapper my-16">
          <div className="bg-gradient-to-r from-[#133D56] to-[#6756D4] h-[5px] w-16 mx-auto"></div>
          <h1 className="h2 font-bold mt-5 text-center">
            Your Journey to Forex CRM Excellence: A Simple 6-Step Process
          </h1>
          <p className="text-center">
            At Neptunefxcrm, we've refined our CRM implementation process to
            ensure your transition is smooth and efficient. Here's how we work
            together to get your forex brokerage up and running with our CRM
            solution:
          </p>

          <div className="lg:grid md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12">
            {steps?.map((step) => (
              <div className="border-2 px-4 py-8 rounded-xl bg-white">
                <div className="flex gap-2 font-bold">
                  <span className="text-[#6C57DB]">#{step?.id} </span>
                  <p>{step?.title}</p>
                </div>
                <p className="text-[#718096] leading-7 text-lg mt-4">
                  {step?.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerConnection;
