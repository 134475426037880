import React, { useState } from "react";
import { Input } from "antd";
import axios from "axios";
import toast from "react-hot-toast";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    description: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        "https://neptunefxcrm.com/crm-email/crm-demo-req",
        {
          name: `${formData?.fname} ${formData?.lname}`,
          email: formData?.email,
          phone: formData?.phone,
          description: formData?.description,
          companyName: "neptunefxcrm",
          website: "neptunefxcrm.com",
        }
      );
      if (data?.success) {
        toast.success(data?.message);
        setFormData({
          fname: "",
          lname: "",
          email: "",
          phone: "",
          description: "",
        });
      }
    } catch (error) {
      console.error("Submission Error:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="wrapper my-16 pb-16">
      <div className="bg-gradient-to-r from-[#133D56] to-[#6756D4] h-[5px] w-16 mx-auto"></div>
      <h1 className="h1 my-5 text-center">Contact Us</h1>
      <div
        id="demo-form"
        className="bg-[#F9F8FF] rounded-xl mt-16 lg:p-16 p-4 xl:w-3/5 mx-auto"
      >
        <form className="w-full" autoComplete="off" onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-2 gap-6">
            <div className="my-5 md:my-0">
              <Input
                name="fname"
                value={formData.fname}
                onChange={handleInputChange}
                size="large"
                className="py-3 ps-4"
                placeholder="Enter First Name"
                required
              />
            </div>
            <div className="my-5 md:my-0">
              <Input
                name="lname"
                value={formData.lname}
                onChange={handleInputChange}
                size="large"
                className="py-3 ps-4"
                placeholder="Enter Last Name"
                required
              />
            </div>
            <div className="my-5 md:my-0">
              <Input
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                size="large"
                className="py-3 ps-4"
                placeholder="Enter Email"
                required
              />
            </div>
            <div className="my-5 md:my-0">
              <Input
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                size="large"
                className="py-3 ps-4"
                placeholder="Phone Number"
                type="number"
                required
              />
            </div>
            <div className="md:col-span-2 my-5 md:my-0">
              <Input.TextArea
                name="description"
                rows={5}
                value={formData.description}
                onChange={handleInputChange}
                size="large"
                className="py-3 ps-4 !resize-none"
                placeholder="Enter Subject"
                required
              />
            </div>
          </div>
          <div className="flex items-center justify-center mt-12">
            <button
              type="submit"
              className="font-bold text-white text-lg rounded-full py-2 px-16 bg-gradient-to-r from-[#133D56] to-[#6756D4]"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
