import "./App.css";
import Header from "./components/header/Header";
import Main from "./components/mainSection/Main";
import Footer from "./components/footer/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./components/contact/ContactUs";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
